import React, { useState, useEffect } from 'react';
import { Scissors, Clock, Gift } from 'lucide-react';

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const ComingSoonPage: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const launchDate = new Date('2024-11-11T00:00:00').getTime();

    const timer = setInterval(() => {
      const now = new Date().getTime();
      const difference = launchDate - now;

      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      });

      if (difference < 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{
      minHeight: '100vh',
      background: 'linear-gradient(to bottom right, #9333ea, #3b82f6)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      textAlign: 'center'
    }}>
      <div>
        <h1 style={{ fontSize: '3rem', fontWeight: 'bold', marginBottom: '1rem' }}>
          DealsBunny
          <Scissors style={{ display: 'inline-block', marginLeft: '0.5rem' }} size={48} />
        </h1>
        <p style={{ fontSize: '1.5rem', marginBottom: '2rem' }}>Hop into Savings Soon!</p>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginBottom: '3rem' }}>
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit}>
              <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{value}</div>
              <div style={{ fontSize: '0.875rem', textTransform: 'uppercase' }}>{unit}</div>
            </div>
          ))}
        </div>

        <div style={{
          background: 'white',
          color: '#9333ea',
          padding: '1.5rem',
          borderRadius: '0.5rem',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          maxWidth: '28rem',
          margin: '0 auto'
        }}>
          <h2 style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <Clock style={{ marginRight: '0.5rem' }} /> Launch Countdown
          </h2>
          <p style={{ marginBottom: '1rem' }}>
            Get ready for a hopping good time with deals that will make your wallet smile!
          </p>
          <ul style={{ textAlign: 'left', listStyleType: 'disc', paddingLeft: '1.5rem', marginBottom: '1rem' }}>
            <li>Exclusive coupons</li>
            <li>Flash sales</li>
            <li>Daily deals</li>
          </ul>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Gift size={32} />
          </div>
        </div>

      
      </div>
    </div>
  );
};

export default ComingSoonPage;
